/** Override Material styles */

@use '../../app/theme/style/variables';

.mat-card {
  margin-bottom: variables.$gutter;
}

::ng-deep .custom-dialog-class {
  padding: 0px;
}

.custom-dialog-class {
  mat-dialog-container {
    padding: 0;
  }
}

.mat-accordion {
  display: block;
  margin-bottom: variables.$gutter;
}

.mat-list-base {
  position: relative;
}

.mat-checkbox,
.mat-radio-button,
.mat-slide-toggle {
  margin-right: variables.$gutter * .5;

  [dir='rtl'] & {
    margin-right: auto;
    margin-left: variables.$gutter * .5;
  }
}

.form-field-full {
  .mat-form-field {
    width: 100%;
  }
}