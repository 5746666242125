@use 'sass:color';
@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $is-dark-theme: map.get($theme, is-dark);
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

.matero-sidemenu {
  >.menu-item {
    >.menu-heading {

      &:hover,
      &:focus {

        background:  rgba(mat.get-color-from-palette($primary), .15) !important;
      }
    }

    &.active {
      >.menu-heading {
        color:#fff;
        background-color: mat.get-color-from-palette($accent) !important;

        &:hover,
        &:focus {
          background:#ad8847 !important;
        }
      }
    }

    &.expanded {
      background:#ad8847 !important;
    }
  }



}

}
