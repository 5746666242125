@use 'variables' as *;
@use 'mixins' as *;

.icon-18 {
  @include icon(18);
}

.icon-24 {
  @include icon(24);
}

.icon-36 {
  @include icon(36);
}

.icon-48 {
  @include icon(48);
}
