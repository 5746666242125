@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $is-dark-theme: map.get($theme, is-dark);
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $background: mat.get-color-from-palette($theme, background);
  $foreground: mat.get-color-from-palette($theme, foreground);

  .matero-topmenu {
    background: mat.get-color-from-palette($background, app-bar);

    .mat-button {
      color: mat.get-color-from-palette($foreground, text);

      &.active {
        background-color: mat.get-color-from-palette($background, focused-button);
      }
    }
  }

  .matero-topmenu-panel {
    .mat-menu-item {
      color: mat.get-color-from-palette($foreground, text);

      &.active {
        color: mat.get-color-from-palette($accent);
      }
    }
  }
}
