@use 'sass:color';
@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $primary: map.get($theme, primary);
  $foreground: map.get($theme, foreground);

  a {
    color: mat.get-color-from-palette($primary);

    &:hover {
      color: color.adjust(mat.get-color-from-palette($primary), $lightness: 10%);
    }
  }

  code {
    background-color: rgba(mat.get-color-from-palette($foreground, secondary-text), .03);
  }
}
