@use '@angular/material' as mat;
@use 'app-theme';

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

//
// Light theme
//
$md-accent: (
    50 : #f5f1e9,
    100 : #e6dbc8,
    200 : #d6c4a3,
    300 : #c6ac7e,
    400 : #b99a63,
    500 : #ad8847,
    600 : #a68040,
    700 : #9c7537,
    800 : #936b2f,
    900 : #835820,
    A100 : #ffe2c0,
    A200 : #ffcb8d,
    A400 : #ffb35a,
    A700 : #ffa841,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-primary: (
    50 : #e4e4e4,
    100 : #bdbcbc,
    200 : #919090,
    300 : #646464,
    400 : #434242,
    500 : #222121,
    600 : #1e1d1d,
    700 : #191818,
    800 : #141414,
    900 : #0c0b0b,
    A100 : #e76c6c,
    A200 : #e04040,
    A400 : #ec0000,
    A700 : #d30000,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$primary: mat.define-palette($md-primary);
$accent: mat.define-palette($md-accent);
$theme: mat.define-light-theme(
  (
    color: (
      primary: $primary,
      accent: $accent
    ),
    typography: mat.define-typography-config(),
    density: 0
  )
);

//////////////////////////////////////////////////////////////////////////////////////
// When we use the Schematics (`ng add`) to start, the following variables will be  //
// used to create a dark theme! This strategy will not generate redundant codes.    //
//////////////////////////////////////////////////////////////////////////////////////

//
// Dark theme
//
// $primary: mat.define-palette(mat.$blue-palette);
// $accent: mat.define-palette(mat.$amber-palette, A200, A100, A400);
// $theme: mat.define-dark-theme($primary, $accent);

@include app-theme.theme($theme);

//
// Dark theme
//
.theme-dark {
  color-scheme: dark;

  $primary: mat.define-palette(mat.$blue-palette);
  $accent: mat.define-palette(mat.$amber-palette, A200, A100, A400);
  $theme: mat.define-dark-theme(
    (
      color: (
        primary: $primary,
        accent: $accent
      ),
      typography: null,
      density: null
    )
  );

  @include app-theme.theme($theme);
}
